<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <Container
    v-if="!isMobileMode"
    :mt="mt"
    :align="align"
    :width="width"
    @click.stop
  >
    <template v-for="(option, index) in options" :key="index">
      <Option
        v-if="
          handleCheckRoleDisplayMenu(userRole, option.role) &&
          (option?.isBookFor && isGroup ? !!bookFor : true)
        "
        :active="isActiveMenu(option.path)"
        @click="handleAction(option)"
      >
        <WrapperHeader>
          <Header>
            <span v-if="option.header === 'accountName'">
              {{ userData.fullname }}
            </span>
            <span v-else>{{ option.header }}</span>
          </Header>
          <Wrapper>
            <OptionNameWrapper>
              <span :class="`icon ${option.icon}`"></span>
              <PrimaryTextSmall class="no-margin-bottom">
                {{ option.name }}
              </PrimaryTextSmall>
            </OptionNameWrapper>
            <OptionCount v-if="option.notiAmount">
              {{ option.notiAmount }}
            </OptionCount>
          </Wrapper>
        </WrapperHeader>
      </Option>
    </template>
  </Container>
  <ContainerMobile
    v-else
    :mt="mt"
    :align="align"
    :mobile="isMobileMode"
    :ipad="isIpad"
    @click.stop
  >
    <div v-for="(menu, index) in options" :key="index">
      <GroupContainer
        v-if="
          handleCheckRoleDisplayMenu(userRole, menu.role) &&
          (option?.isBookFor && isGroup ? !!bookFor : true)
        "
      >
        <GroupHeader>
          <NormalTextSmall v-if="menu.name" class="no-margin-bottom">
            {{ menu.name }}
          </NormalTextSmall>
        </GroupHeader>
        <template v-for="(option, index1) in menu.options" :key="index1">
          <OptionMobile
            v-if="
              handleCheckRoleDisplayMenu(userRole, option.role) &&
              (option?.isBookFor && isGroup ? !!bookFor : true)
            "
            :active="isActiveMenu(option.path)"
            @click="handleAction(option)"
          >
            <OptionMobileWrapper>
              <Header>
            <span v-if="option.header === 'accountName'">
              {{ userData.fullname }}
            </span>
            <span v-else>{{ option.header }}</span>
          </Header>
              <Wrapper>
                <OptionNameWrapper>
                  <span :class="`icon ${option.icon}`"></span>
                  <PrimaryTextSmall class="no-margin-bottom">
                    {{ option.name }}
                  </PrimaryTextSmall>
                </OptionNameWrapper>
                <OptionCount v-if="option.notiAmount">
                  {{ option.notiAmount }}
                </OptionCount>
              </Wrapper>
            </OptionMobileWrapper>
          </OptionMobile>
        </template>
        <el-divider v-if="index !== options.length - 1" class="Divider" />
      </GroupContainer>
    </div>
  </ContainerMobile>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import * as styles from './styles'
import * as texts from '@/components/ui/styled/text/index'
import { isIpad } from '@/helpers/checkIsMobileDevice'

export default {
  name: 'OptionV2',
  components: {
    ...styles,
    ...texts,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    mt: {
      type: Number,
      default: 50,
    },
    align: {
      type: String,
      default: 'left',
    },
    isMobileMode: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 301,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMobileView: window.innerWidth <= 500,
      isIpad: isIpad(),
    }
  },
  computed: {
    ...mapGetters('account', [
      'userData',
      'userGroup',
      'isBrandAdminRole',
      'isBrandGeneralRole',
      'isAdminRole',
    ]),
    ...mapState('account', ['bookFor']),
    userRole() {
      if (this.isBrandAdminRole) {
        return 'brandAdmin'
      }
      if (this.isBrandGeneralRole) {
        return 'brandGeneral'
      }
      if (this.userGroup === 'venue') {
        return this.isAdminRole ? 'venueAdmin' : 'venueGeneral'
      }
      if (this.userGroup === 'venueGroup') {
        return this.isAdminRole ? 'venueGroupAdmin' : 'venueGroupGeneral'
      }

      return this.userGroup
    },
    isGroup() {
      return this.userGroup === 'venueGroup'
    },
  },
  watch: {
    isActive() {
      this.toggleScroll(this.isMobileView)
    },
    bookFor() {
      // trigger rerender
    },
  },
  mounted() {
    this.toggleScroll(this.isMobileView)
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    toggleScroll(enable) {
      if (enable && this.isMobileMode && this.isActive) {
        document.body.classList.add('no-scroll')
      } else {
        document.body.classList.remove('no-scroll')
      }
    },
    handleResize() {
      this.isMobileView = window.innerWidth <= 500
      this.toggleScroll(this.isMobileView)
    },
    redirectTo(path) {
      if (!path) return
      window.location.href = path
    },
    handleAction(option) {
      if (option.path) {
        this.redirectTo(option.path)
      }
      if (option.action) {
        option.action.call(this)
      }

      this.$emit('handleCloseMenu')
    },
    isActiveMenu(path) {
      if (!path) return false
      return this.$route.path === path
    },
    handleCheckRoleDisplayMenu(role, whiteList) {
      if (!role && !whiteList?.length) return false
      if (whiteList.includes('all')) return true
      return whiteList.includes(role)
    },
  },
}
</script>
