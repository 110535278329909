export default {
  items: [],
  isShowBasketOverlay: false,
  isMassBooking: false,
  campaignMassBook: null,
  storeAddressTo: null,
  deliveryAddresses: [],
  discount: {},
  isPaidBasket: true,
  basketData: {},
  historyBasket: [],
  delierySelectedAddress: {},
  isBindedData: false,
  isTerritoryMassBook: false,
  territoryMassBook: null,
  isHideSpacialRequest: false
}
