// import * as constant from '../mutation-constant'
import { setUserBasket, clearUserBasket } from '../../helpers/localStorage'
import calculateBreakdownPrice from '../../helpers/calculateBreakdownPrice'
import { v4 as uuidv4 } from "uuid";

export default {
  addBasketSuccess: (state, payload) => {
    state.items.push(payload)
    setUserBasket(state.items)
  },
  addBasketError: (state, payload) => {
    state.error = payload
  },
  removeBasketSuccess: (state, payload) => {
    state.items = payload
    setUserBasket(payload)
  },
  removeBasketByIndex(state, index) {
    state.items.splice(index, 1)
  },
  removeBasketById(state, id) {
    const findIndex = state.items.findIndex((item) => item.id === id)
    if (findIndex > -1) {
      state.items.splice(findIndex, 1)
    }
  },
  addToHistoryBasket: (state, payload) => {
    state.historyBasket.push(payload)
  },
  removeAllBasketSuccess: (state) => {
    state.items = []
    state.discount = {}
    state.historyBasket = []
    state.isBindedData = false
    state.campaignMassBook = null
    state.territoryMassBook = null
    state.isTerritoryMassBook = false
    state.isMassBooking = false
    state.isHideSpacialRequest = false
    clearUserBasket()
  },
  updateKeyContact: (state, payload) => {
    const index = state.items.findIndex((item) => item.id === payload.basketId)
    const tempData = JSON.parse(JSON.stringify(state.items[index].keyContact))
    Object.assign(tempData, payload)
    if (index > -1) {
      state.items[index].keyContact = tempData
      // Object.assign(state.items[index].keyContact, payload)
    }
  },
  updateSpacialRequest: (state, payload) => {
    const index = state.items.findIndex((item) => item.id === payload.basketId)

    if (index > -1) {
      state.items[index].bookingData.specialRequests = payload.data
    }
  },
  updateEventTime: (state, payload) => {
    state.items.map((basket) => {
      if (basket.id === payload.id) {
        basket.bookingData.eventStart = payload.bookingData.eventStart
        basket.bookingData.eventEnd = payload.bookingData.eventEnd
      }
      return basket
    })

  },
  updateBookingItems: (state, payload) => {
    const findItemIndex = state.items.findIndex(
      (item) => item.id === payload.basketId
    )
    const tempData = JSON.parse(JSON.stringify(state.items))
    tempData[findItemIndex].bookingItems = payload.bookingItems
    // Only set contactConfirmed for Brand book for (venue and venue group not set this).
    if (payload.hasOwnProperty('contactConfirmed')) {
      tempData[findItemIndex].contactConfirmed = payload.contactConfirmed
    }
    state.items = tempData
  },
  updateBasketItem: (state, payload) => {
    const findItemIndex = state.items.findIndex(
      (item) => item.id === payload.itemId
    )
    state.items[findItemIndex].addressConfirmed = payload.addressConfirmed
  },
  updateForwardEmail: (state, payload) => {
    const findItemIndex = state.items.findIndex(
      (item) => item.id === payload.basketId
    )
    const tempData = Object.assign([], state.items)
    tempData[findItemIndex].forwardUserId = payload.forwardUserId
    delete tempData[findItemIndex].budgetCode
    state.items = tempData
  },
  updateBudgetCode: (state, payload) => {
    const findItemIndex = state.items.findIndex(
      (item) => item.id === payload.basketId
    )
    const tempData = Object.assign([], state.items)
    tempData[findItemIndex].budgetCode = {}
    tempData[findItemIndex].budgetCode._id = payload.budgetCodeId
    tempData[findItemIndex].budgetCode.name = payload.budgetCodeName
    delete tempData[findItemIndex].forwardUserId
    state.items = tempData
  },
  removeBudgetCode: (state, payload) => {
    const findItemIndex = state.items.findIndex(
      (item) => item.id === payload.basketId
    )
    const tempData = Object.assign([], state.items)
    delete tempData[findItemIndex].budgetCode
    state.items = tempData
  },
  setStoreAddressTo: (state, payload) => {
    state.storeAddressTo = payload
  },
  setDeliveryAddresses: (state, payload) => {
    state.deliveryAddresses = payload
  },
  updateDeliveryAddresses: (state, payload) => {
    state.deliveryAddresses.unshift(payload)
  },
  toggleBasketOverlay: (state, payload) => {
    state.isShowBasketOverlay = payload
  },
  updateBasketPriceBreakdown: (state, payload) => {
    state.items = state.items.filter(
      (item, index) => index === state.items.length - 1
    )
    state.items.forEach((item) => {
      const {
        quantity,
        bookingData: {
          campaign: { allowedMultiplePurchase },
        },
      } = item
      item.bookingData.campaign.priceBreakdown = payload
      item.campaign.priceBreakdown = payload

      // Calculate additional price
      const getBreakdownPrice = calculateBreakdownPrice(
        payload,
        quantity,
        allowedMultiplePurchase
      )
      item.bookingData.campaign.priceBreakdown.additionalPrice = {
        quantity,
        price: getBreakdownPrice.totalPriceIncVat,
      }
    })
  },
  setDiscountCode: (state, payload) => {
    state.discount = payload
    state.items.forEach((item) => {
      payload.campaigns.forEach((discountCode) => {
        if (item.id === discountCode.id) {
          item.bookingData.discount = discountCode
        }
      })
    })
  },
  clearDiscountCode: (state) => {
    state.discount = {}
    state.items.forEach((item) => {
      item.bookingData.discount = {}
    })
  },
  setIsPaidBasket: (state, payload) => {
    state.isPaidBasket = payload
  },
  updateBasketDisplay: (state, payload) => {
    state.basketData = { ...payload }
  },
  setAddressSelected: (state, payload) => {
    state.delierySelectedAddress = { ...payload }
  },
  setBinding: (state, value) => {
    state.isBindedData = value
  },
  setMassBooking: (state, payload) => {
    state.isMassBooking = payload
  },
  setCampaignMassbook: (state, payload) => {
    state.campaignMassBook = payload
    state.isTerritoryMassBook = false
  },
  setTerritoryMassbook: (state, payload) => {
    state.territoryMassBook = payload
    state.isTerritoryMassBook = true
    state.isMassBooking = false

  },
  setHideSpacialRequest(state, payload) {
    state.isHideSpacialRequest = payload
  },
  removeVenueTerritory: (state, payload) => {
    const groupIndex = state.territoryMassBook.venues.findIndex((group) => group.group === payload.group);

    if (groupIndex !== -1) {
      const updatedVenues = state.territoryMassBook.venues[groupIndex].venues.filter((venue) => venue.venueId !== payload.venueId);

      if (updatedVenues.length === 0) {
        state.territoryMassBook.venues.splice(groupIndex, 1);
      } else {
        state.territoryMassBook.venues[groupIndex].venues = updatedVenues;
      }
    }
    // eslint-disable-next-line no-undef
  },
  removeVenueTerritoryByVenueId: (state, payload) => {
    state.territoryMassBook.venues.forEach((group) => {
      const index = group.venues.findIndex((venue) => venue.venueId === payload.venueId);
      if (index !== -1) {
        group.venues.splice(index, 1);
      }
    });

    state.territoryMassBook.venues = state.territoryMassBook.venues.filter(group => group.venues.length > 0);
  },
  clearTerritory: (state) => {
    state.items = []
    state.historyBasket = []
    state.territoryMassBook = null
    state.isTerritoryMassBook = false
  },
  clearAllBasket: (state) => {
    state.items = []
    state.historyBasket = []
    state.territoryMassBook = null
    state.isTerritoryMassBook = false
    state.campaignMassBook = null
    state.isMassBooking = false
    state.isHideSpacialRequest = false
  },
  checkOutTeerritory: (state) => {
    state.historyBasket = []
    const newBaskets = []
    state.territoryMassBook.venues.forEach((groups) => {
      groups.venues.forEach((venue) => {
        newBaskets.push({
          ...venue,
          coreKits: venue.coreKits.map((coreKit) => {
            return {
              ...coreKit,
              amount: Number(coreKit.amount)
            }
          })
        })
      })
    });

    state.historyBasket = newBaskets
    const mapData = newBaskets.map((newBasket) => {

      // eslint-disable-next-line no-unused-vars
      const { matchCampaign, venueType, groupId, groupName, addOnAssets, storeChecked, coreKits, signedUpVenue, venueTypeName, checked, ...newBasketExcludeData } = newBasket


      return {
        ...state.campaignMassBook.templateData,
        id: uuidv4(),
        coreKits: newBasket.coreKits,
        addOnAssets: newBasket.addOnAssets,
        bookingData: {
          ...state.campaignMassBook.templateData.bookingData,
          venue: newBasketExcludeData,

        },

      }
    })
    state.items = mapData
  }
}
