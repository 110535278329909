<template>
  <div :class="[$options.name, 'wrapper-input']">
    <label v-if="label" :class="labelClass">
      {{ label }}
      <i v-if="optional">optional</i>
    </label>
    <input
      :id="id"
      ref="inputBox"
      :class="[inputClass, { error: emptyField || redBorder }]"
      :name="name"
      :type="type"
      :placeholder="placeholder"
      :value="modelValue"
      :min="min"
      :max="max"
      :disabled="isDisable"
      :pattern="pattern"
      :maxlength="maxLengthText"
      @keydown="preventNonNumeric"
      @click="onClick"
      @input="onInput"
      @focus="onFocus"
    />
    <div v-show="emptyField" class="messageError mb-0">{{ errorMessage }}</div>
  </div>
</template>

<script>
'use strict'

export default {
  name: 'Input',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    labelClass: {
      type: String,
      default: '',
    },
    inputClass: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: null,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    optional: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number, Boolean, Array, Object],
      default: '',
    },
    pattern: {
      type: String,
      default: '',
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    decimal: {
      type: Number,
      default: null,
    },
    emptyField: {
      type: Boolean,
      default: false,
    },
    redBorder: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: 'This field is required.',
    },
    maxLengthText: {
      type: String,
      default: null,
    },
    onlyNumberic: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    preventNonNumeric(event) {
      if (!this.onlyNumberic) return

      if (event.key === '-' || event.key === '+') {
        event.preventDefault()
      }
    },
    onInput: function (event) {
      // // Bind v-model
      // if (
      //   event.target.value.indexOf('.') == -1 &&
      //   this.type === 'number' &&
      //   this.decimal >= 0 &&
      //   this.decimal !== null
      // ) {
      // this.$emit('update:modelValue', event.target.value);
      //   return
      // }
      // if (
      //   event.target.value.length - event.target.value.indexOf('.') >
      //     this.decimal &&
      //   this.type === 'number' &&
      //   this.decimal >= 0 &&
      //   this.decimal !== null
      // ) {
      //   event.target.value = parseFloat(event.target.value).toFixed(
      //     this.decimal
      //   )
      // }
      // // if (event.target.value) {
      // //   this.emptyField = false
      // // }
      if (this.type === 'number') {
        return this.$emit('update:modelValue', Number(event.target.value))
      }

      this.$emit('update:modelValue', event.target.value)
    },
    focus() {
      this.$refs.inputBox && this.$refs.inputBox.focus()
    },
    onFocus: function (event) {
      // Bind v-model
      // if (
      //   event.target.value.indexOf('.') == -1 &&
      //   this.type === 'number' &&
      //   this.decimal >= 0 &&
      //   this.decimal !== null
      // ) {
      //   this.$emit('focus', event.target.value)
      //   return
      // }
      // if (
      //   event.target.value.length - event.target.value.indexOf('.') >
      //     this.decimal &&
      //   this.type === 'number' &&
      //   this.decimal >= 0 &&
      //   this.decimal !== null
      // ) {
      //   event.target.value = parseFloat(event.target.value).toFixed(
      //     this.decimal,
      //   )
      // }

      if (this.type === 'number') {
        return this.$emit('focus', Number(event.target.value))
      }

      this.$emit('focus', event.target.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper-input {
  input {
    height: 44px;
    border-radius: 5px;
    @include sf-font(16px, $text_placeholder, 'normal');
    &.error {
      border: 1px solid $danger;
    }
    &:focus {
      border: 1px solid $tertiary;
    }
  }
}
</style>
